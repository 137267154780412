html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #5A5076;
    --heding-primary-color: #2E2056;
    --bg-primary-color: #8140EA;
    --link-primary-color: #72DBF6;
    --button-primary-color: #4EEFA4;
}
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body {
    font-size: 14px;
    line-height: 26px;
    font-family: 'Raleway', sans-serif;
    color: #FFFFFF;
    background: #FFFFFF;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 136.4%;
    color: #FFFFFF;
    margin: 20px 0;
}

h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 136.4%;
    color: var(--heding-primary-color);
    margin: 20px 0;
}

h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 136.4%;
    color: var(--heding-primary-color);
    margin: 20px 0;
}

p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: var(--text-primary-color);
    padding: 10px 0;
}

.text-center {
    text-align: center;
}

.text-white {
    color: #FFFFFF
}

.grid {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}

.col2 {
    grid-template-columns: 1fr 1fr;
}

.col2-1 {
    grid-template-columns: 2fr 1fr;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
    ;
}

a.button {
    background: linear-gradient(95.18deg, #50FBA9 23.23%, #45C98A 85.02%);
    box-shadow: 0px -28px 73px -11px rgba(133, 126, 146, 0.5);
    border-radius: 69px;
    padding: 20px 40px;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition-duration: .6s;
    display: block;
}

a.primary {
    background: linear-gradient(95.18deg, #50FBA9 23.23%, #45C98A 85.02%);
    box-shadow: 0px -28px 73px -11px rgba(133, 126, 146, 0.5);
    width: max-content;
    position: relative;
}
a.primary::after{
    content: '';
    position: absolute;
    width: 104%;
    height: 107%;
    top: -5px;
    left: -8px;
    right: 0;
    transition: 0.3s;
    background: linear-gradient(188.43deg, #45307E 3.27%, rgba(67, 48, 124, 0) 96.37%);
    border-radius: 69px;
    z-index: -1;
}

a.secondary {
    background: linear-gradient(95.18deg, #46B588 23.23%, var(--button-primary-color) 85.02%);
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
}

a.secondary:hover,
a.primary:hover {
    background: linear-gradient(94.3deg, #46B588 -1.78%, #02C2FF -1.77%, #58D7FF 84.81%);
}

/* body */


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    position: relative;
    z-index: 100;
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 20px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    padding: 0;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header {
    width: 100%;
    background: var(--bg-primary-color);
    position: relative;

}

.site-logo {
    position: relative;
    z-index: 3;
    padding: 20px 0;
}

.site-logo .main-logo, .footer .main-logo {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 30px;
    vertical-align: middle;
    font-size: 0;
    background: url(../image/logo.svg) no-repeat 0 0 / contain;
}

.intro-primary {
    width: 100%;
    background: var(--bg-primary-color);
    padding-bottom: 80px;
    margin-bottom: 70px;
    position: relative;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 0;
}

.blur-circle {
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--bg-primary-color);
    filter: blur(150px);
}

.blur-circle-right {
    position: absolute;
    width: 20%;
    height: 25%;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background: var(--bg-primary-color);
    filter: blur(150px);

}

.blur-circle-bottom {
    position: absolute;
    width: 26%;
    height: 14%;
    top: 50%;
    left: 30%;
    right: 0;
    bottom: 0;
    background: var(--bg-primary-color);
    filter: blur(150px);
}

.intro-primary .title {
    position: relative;
    z-index: 3;
}

.intro-highlight {
    color: #72DBF6;
}

.intro-entry {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 60px;
}
.main-image{
    background-image: url("../image/top-image.webp"); 
    height: 500px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sub-title {
    padding: 0 100px;
}

/* table */
.t-top-title {
    background-image: url(../image/arrows2.png), url(../image/arrows1.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
}

.t-top-tabs {
    display: table;
    margin: 0 auto;
}

ul#horizontal-list {
    min-width: 696px;
    list-style: none;
    padding-top: 20px;
    margin: 40px 0;
}

ul#horizontal-list li {
    display: inline;
    padding: 25px 5px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 19px rgba(46, 32, 86, 0.1);
    border-radius: 10px;
    margin: 0 20px;

}

ul#horizontal-list li a {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #A19BB3;
    text-decoration: none;
    padding: 17px 23px;
    border-bottom: 3px solid #A19BB3;
    border-radius: 2px;
    transition-duration: .2s;
}

ul#horizontal-list li.active a {
    font-weight: 600;
    color: #2E2056;
    border-bottom: 3px solid var(--button-primary-color);
}

ul#horizontal-list li:hover {
    cursor: pointer;
}

ul#horizontal-list li:hover a {
    border-bottom: 3px solid var(--button-primary-color);
}

ul#horizontal-list li.active a::after {
    content: '';
    position: absolute;
    left: 45%;
    top: 94%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--button-primary-color);
    clear: both;
    z-index: 2;
}

ul#horizontal-list li.active::after {
    content: '';
    position: absolute;
    left: 45%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #FFFFFF;
    clear: both;
}

.t-section {
    background: #362562;
    border-radius: 26px;
    padding: 15px;
    margin: 30px 0;
}

.mytabs {
    /* display: none; */
    height: 0;
    opacity: 0;
}

.mytabs >  div {
    overflow: hidden;
}

.mytabs.active {
    height: auto;
    opacity: 1;
    /* display: block; */
    transition: opacity 0.3s linear;
}

.d-none {
    display: none;
}

table.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #2E2056;
}

.home-table thead tr {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #96A0AE;
    text-transform: uppercase;
}

.home-table tbody tr {
    padding: 5px 5px 5px 20px;
    border-radius: 28px;
    margin: 15px 0;
}

table.home-table td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 10px;
    background-color: #F9FBFF;
    text-align: center;
    max-width: 250px;
    position: relative;
}

table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(5))::after {
    content: '';
    height: 80%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #F1F4F9;
}

table.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

table.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
table.home-table li{
    margin-left: 20px;
}
.t-logo .logo {
    content: '';
    width: 210px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    margin: 0px 30px 0 0px;
    font-size: 0px;
    background-color: #F1F4F9;
    border-radius: 25px;
    padding: 15px 0px;
}
.t-logo .logo.logo-opensea{
    background-image:  url("../image/operators/logo-opensea.png");
}
.t-logo .logo.logo-binance{
    background-image:  url("../image/operators/binance_logo.png");
}
.t-logo .logo.logo-crypto{
    background-image:  url("../image/operators/logo-crypto-com.png");
}
.t-logo .logo.logo-veve{
    background-image:  url("../image/operators/veve_logo.png");
}
.t-logo .logo.logo-rarible{
    background-image:  url("../image/operators/rarible_logo.png");
}
.t-logo .logo.logo-pantograph{
    background-image:  url("../image/operators/logo-pantograph.png");
}
.t-logo .logo.logo-sketchar{
    background-image:  url("../image/operators/logo-sketchar.png");
}
.t-logo .logo.logo-ipfs{
    background-image:  url("../image/operators/logo-ipfs.png");
}
.t-logo .logo.logo-lucidpix{
    background-image:  url("../image/operators/logo-lucidpix.png");
}
.t-logo .logo.logo-qwnerfy{
    background-image:  url("../image/operators/qwnerfy_logo.png");
}
.t-logo .logo.logo-coinbase{
    background-image:  url("../image/operators/coinbase_logo.png");
}
.t-logo .logo.logo-enjin{
    background-image:  url("../image/operators/logo-enjin.png");
}
.t-logo .logo.logo-ellipal{
    background-image:  url("../image/operators/logo-ellipal.png");
}
.t-logo .logo.logo-metamask{
    background-image:  url("../image/operators/metamask_logo.png");
}
.t-logo .logo.logo-rainbow{
    background-image:  url("../image/operators/rainbow_logo.png");
}
.t-logo .logo.logo-oxuniverse{
    background-image:  url("../image/operators/oxuniverse_logo.png");
}
.t-logo .logo.logo-splinterlands{
    background-image:  url("../image/operators/splinterlands_logo.png");
}
.t-logo .logo.logo-cropbytes{
    background-image:  url("../image/operators/cropbytes_logo.png");
}
.t-logo .logo.logo-forestknight{
    background-image:  url("../image/operators/forestknight_logo.png");
}
.t-logo .logo.logo-chainmonsters{
    background-image:  url("../image/operators/chainmonsters-logo.png");
}

.t-os-icon{
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    height: 30px;
    margin: 0 auto;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}
.t-os-icon.iphone-android{
    background-image:  url(../image/iphone.svg), url(../image/android.svg);
    background-position: left, right;
}
.t-os-icon.android{
    background-image:  url("../image/android.svg");
    background-position: center;
}
.t-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 80px;
}
.t-rating .icon{
    content: '';
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    margin: 0px 10px 0 0px;
    font-size: 0px;
    background-image: url(../image/star.png);
}
.t-rating img:first-child,
.t-os img:first-child {
    margin-right: 15px;
}

ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
}

ul.list-highlights li::before {
    content: "\2022";
    font-family: Poppins;
    color: var(--link-primary-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.what-is {
    background: #F5F4F7;
    border-radius: 30px;
    margin: 70px 0 60px;
}

.what-is .inner-img {
    display: flex;
}

.what-is .inner {
    text-align: left;
    padding: 50px 50px 50px 30px;
}

.types {
    top: 70px;
    position: relative;
}

.types .type {
    background: #FFFFFF;
    box-shadow: 0px 4px 19px rgba(46, 32, 86, 0.1);
    border-radius: 30px;
    padding: 30px;
    margin: 30px 0;
}

.types .type:first-child {
    margin-top: 0px;
}

.types .type .type-title {
    background-size: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.type-title.marketplace_icon{
    background-image: url(../image/marketplace_icon.png);
}
.type-title.wallet_icon{
    background-image: url(../image/wallet_icon.png);
}
.type-title.creator_icon{
    background-image: url(../image/creator_icon.png);
}
.type-title.game_icon{
    background-image: url(../image/game_icon.png);
}
.types .type .type-title h3 {
    position: relative;
    left: 40px;
}

.compatibility-box {
    background: #342561;
    padding: 100px 0 80px 0;
}

.compatibility-title {
    margin: 40px 0;
}

.compatibility-box .iphone,
.compatibility-box .android {
    position: relative;
    z-index: 2;
}

.compatibility-box .iphone::after,
.compatibility-box .android::after {
    content: "";
    background-size: contain !important;
    background-repeat: no-repeat !important;
    position: absolute;
    right: 0;
    bottom: 0px;
    opacity: 0.2;
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.compatibility-box .iphone::after {
    background: url(../image/iphone-bg.webp) transparent;
    background-position: left;
    top: -75px;
    left: -64px;
    z-index: -1;
}

.compatibility-box .android::after {
    background: url(../image/android-bg.webp) transparent;
    background-position: right;
    top: 0px;
    left: 0px;
    z-index: -1;
}

/* F&Q */
.faq-wrapper {
    margin-top: 60px;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #EDECEE;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq.expanded .faq-title{
    padding-bottom: 20px;
}

.faq-btn {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
}

.faq-btn:hover {
    cursor: pointer;
}

.faq {
    padding: 10px 30px;
    transition: all 0.1s ease-in;
}
.faq.collapsed .faq-btn {
    background-image: url("../image/faq-expand.svg");
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded .faq-btn {
    background-image: url("../image/faq-collapse.svg");
    background-size: contain;
}

.faq.expanded {
    background: #F5F4F7;
    border-radius: 30px;
    border: none;
}

.faq.faq.expanded+.faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
}

.faq .faq-title h3 {
    pointer-events: none;
}

.faq-listing {
    margin-top: 30px;
}

.read-more-faq {
    width: 100%;
    border: 1px solid var(--link-primary-color);
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: var(--link-primary-color);
}

.read-more-faq:active {
    background-color: #E2FCFC;
}

.conclution-box {
    background: linear-gradient(94.3deg, #46B588 -1.78%, #02C2FF -1.77%, #009FD1 84.81%);
    border-radius: 30px;
    padding: 30px 100px 50px;
    position: relative;
    top: 70px;
}

/* Footer */
.footer {
    background: #1D1535;
    padding: 100px 0 20px;
    color: #9A91B6;
}

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 1400px) {
    .container {
        min-width: 1350px;
    }
    a.button{
        min-width: 150px;
    }
}

@media only screen and (min-width: 868px) and (max-width: 1080px) {
    .container{
        padding: 0 20px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .main-image{
        display: none;
    }
    .what-is .inner-img {
        justify-content: center;
    }
    ul#horizontal-list li{
        margin: 0 10px;
    }
    table.home-table td{
        max-width: 250px;
    }
    .t-logo .logo{
        width: 200px;
    }
    .t-rating{
        justify-content: left
    }
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 867px) {
    
    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    h1{
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
    }
    h2{
        font-size: 24px;
        line-height: 36px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
    p{
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .sub-title {
        padding: 0px;
    }
    .main-image, .blur-circle-bottom, .blur-circle-right, .overlay{
        display: none;
    }
    .site-header, .intro-primary{
        background: #2E2056;
    }
    .intro-primary{
        background-image: url(../image/arrows2.png), url(../image/arrows1.png);
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        margin-bottom: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 30px;
    }
    .intro-entry{
        margin-bottom: 30px;
    }
    .logo {
        padding: 0px;
    }
    .intro-entry{
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }
    a.button{
        display: block;
        padding: 20px;
        text-align: center;
        min-width: auto;
        box-shadow: none;
    }
    .inner-img img{
        width: 100%;
    }
    /* Table */

    .m-slider-nav {
        width: 120px;
        margin: 8px auto 0px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #DEDDE2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: var(--button-primary-color);
    }

    .t-top-tabs {
        overflow: hidden;
        display: block;
    }
    .t-top-title{
        background-image: none;
    }
    
    ul#horizontal-list li {
        transition: margin 0.2s linear;
    }

    ul#horizontal-list{
        min-width: auto;
        width: 1600px;
    }
    ul.list-highlights{
        padding-left: 0px;
    }
    ul.list-highlights li{
        margin-left: 10px;
    }
    .t-logo {
        background: #F1F4F9;
        border-radius: 25px;
        padding: 20px 10px;
        height: auto;
        width: 100%!important;
    }
    .t-logo .logo {
        display: block;
        width: 100px;
        height: auto;
        font-size: 0;
        text-indent: -4000px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        max-width: 100%;
        margin: 0 auto;
        float: none;
    }
    .t-os{
        margin: inherit;
    }
    .t-rating {
        font-size: 14px;
        line-height: 21px;
        color: #2E2056;
    }
    .t-rating{
        justify-content: left
    }
    .home-table thead{
        display: none;
    }
    .home-table tbody tr{
        display: table;
        background: #F9FBFF;
        border-radius: 20px;
        padding: 10px;
        width: 100%;
        max-width: -webkit-fill-available;
    }
    .home-table td {
        border: none!important;
		position: relative;
        text-align: left!important;
        padding: 10px 5px!important;
        display: inline-table;
        max-width: 350px!important;
	}

		.home-table td:before {
			position: absolute;
			top: -6px;
			left: 4px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: left;
            text-transform: uppercase;
            color: #96A0AE;

        }
		.home-table td:nth-of-type(1):before { content: ""; }
		.home-table td:nth-of-type(2):before { content: "Type"; }
		.home-table td:nth-of-type(3):before { content: "Highlights"; }
		.home-table td:nth-of-type(4):before { content: "OS"; }
		.home-table td:nth-of-type(5):before { content: "Rating"; }
        
        .home-table td:nth-of-type(1), .home-table td:nth-of-type(2){width: 48%;line-height: 20px;}
        .home-table td:nth-of-type(4), .home-table td:nth-of-type(5){width: 28%;}
        .home-table td:nth-of-type(3){float: right;width: 62%;}
        .home-table td:nth-of-type(6){width: 100%;display: block;margin: 0 auto;}

        table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(4))::after{
            background-color: transparent;
        }

    .what-is .inner {
       padding: 0 20px 20px;
    }
    .what-is{
        margin: 40px 0;
    }
    #types .sub-title p {
        margin-bottom: 0px;
    }
    .compatibility-box {
        padding: 50px 0 30px 0;
    }
    .compatibility-box .compatibility-title h2, .compatibility-box .compatibility-title p , .t-top-title h2, .t-top-title p{
        text-align: left;
    }
    .compatibility-box .compatibility-title{
        position: relative;
    }
    .compatibility-box .compatibility-title::after{
        content: '';
        height: 2px;
        width: 80%;
        position: absolute;
        right: 40px;
        background-color: #4E3892;
        bottom: -30px;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 40%);
    }
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq {
        padding: 20px 15px;
    }
    .faq.expanded {
        border-radius: 15px;
    }
    .conclution-box{
        padding: 15px;
        margin: 0 8px;
        top: 50px;
    }
    .footer{
        padding-top: 80px;
    }
    .footer-inner{
        justify-content: center;
        padding-bottom: 30px;
        display: grid;
        text-align: center;
    }
}